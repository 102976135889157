<script>
import ERow from "components/layout/components/Row"
import ECol from "components/layout/components/Col"
import ErpInput from "@/plugins/uloc-erp/components/form/input"
import ErpSField from "@/plugins/uloc-erp/components/form/SimpleField"
import TipoBemSelect from "components/bem/components/include/TipoSelect"
import {VMoney} from "v-money"
import {convertRealToMoney, REAL_BRL} from "@/utils/money"
import {criacaoRapida, uploadArquivoBemSync} from "@/domain/bem/services"
import {UProgress} from "uloc-vue"

const _model = {
  numero: null,
  descricao: null,
  identificador: null,
  titulo: null,
  tipoBem: null,
  tipoBemCache: null,
  valorAvaliacao: null,
  valorInicial: null,
  valorInicial2: null,
  descontoSegundaPraca: null,
  lat: null,
  lng: null,
  fotos: [],
  arquivos: [],
  matricula: [],
  entityStatus: 0, //0 - Pendente; 1 - Criando; 100 - Criado, 99 - Falha
  error: null
}

export default {
  name: 'CriacaoRapidaBens',
  directives: {money: VMoney},
  mixins: [],
  props: {
    leilao: {
      required: false
    },
    bem: {
      required: false
    },
    lote: {
      required: false
    }
  },
  components: {
    TipoBemSelect,
    ErpSField,
    ErpInput,
    ECol,
    ERow,
    UProgress
  },
  data () {
    return {
      money: REAL_BRL,
      model: JSON.parse(JSON.stringify(_model)),
      bens: [],
      cache: {
        tipoBemId: null,
        bindDesconto: false,
        action: 0, // 1 - Criando bens; 2 - Fazendo upload
        actionStatus: null,
        actionClass: null,
        fileProgress: 0,
        fileProgressDesc: null,
        copying: false,
        copyingFile: null
      },
      reset: false
    }
  },
  watch: {
    'cache.tipoBemId' (v) {
      this.model.tipoBem = Number(v)
      this.$nextTick(() => {
        this.$refs.tipoBem.$forceUpdate()
      })
    },
    'model.descontoSegundaPraca' (v) {
      const valorInicial = convertRealToMoney(this.model.valorInicial)
      console.log('Mudança ', valorInicial, v)
      this.$nextTick((() => {
        if (valorInicial > 0) {
          const total = valorInicial * (Number(v) / 100)
          console.log(total)
          this.cache.bindDesconto = true
          this.model.valorInicial2 = 'R$ ' + this.$options.filters.moeda(total || 0)
          this.$nextTick(() => {
            this.cache.bindDesconto = false
          })
        }
      }))
    }
  },
  methods: {
    adicionar () {
      const model = JSON.parse(JSON.stringify(this.model))
      model.valorAvaliacao = convertRealToMoney(model.valorAvaliacao)
      model.valorInicial = convertRealToMoney(model.valorInicial)
      model.valorInicial2 = convertRealToMoney(model.valorInicial2)
      model.valorMinimo = convertRealToMoney(model.valorMinimo)
      model.fotos = this.$refs.fotos.files
      model.arquivos = this.$refs.arquivos.files
      model.matricula = this.$refs.matricula.files
      this.bens.push(model)
      this.reset = true
      this.$nextTick(() => {
        this.model = JSON.parse(JSON.stringify(_model))
        this.$nextTick(() => {
          this.reset = false
          this.cache.tipoBemId = null
        })
      })
    },
    remover (bem) {
      this.bens.splice(this.bens.indexOf(bem), 1)
    },
    editar (bem) {
      this.model = bem
      this.bens.splice(this.bens.indexOf(bem), 1)
    },
    async fileToBase64 (file) {
      return new Promise(resolve => {
        const reader = new FileReader()
        reader.onload = (event) => resolve(event.target.result)
        reader.onerror = error => reject(error)
        reader.readAsDataURL(file)
      })
    },
    onUploadProgress (event) {
      let progress = Number(Math.round(
          (event.loaded * 100) / event.total
      ))

      this.cache.fileProgress = progress
      this.cache.actionStatus = `A imagem ${this.cache.copyingFile.name} está ${progress}% carregada... `
    },
    salvar () {
      console.log(this.bens)
      if (!this.bens || !this.bens.length) {
        this.$uloc.dialog({title: 'Nenhum bem registrado para criação.'})
        return
      }
      this.cache.action = 1
      this.cache.actionStatus = 'Preparando criação dos bens...'
      this.bens.filter(b => b.entityStatus !== 100).map(async bem => {
        let bemResponse
        try {
          bem.entityStatus = 1
          this.cache.actionStatus = `Criando bem ${bem.titulo}...`
          bemResponse = await criacaoRapida({
            bem,
            parent: this.bem ? (this.bem.id || this.bem) : null,
            leilao: this.leilao ? (this.leilao.id || this.leilao) : null,
            lote: this.lote ? (this.lote.id || this.lote) : null,
          })
          bem.entityStatus = 100
          this.cache.actionStatus += ' OK!'
          console.log('Bem criado com sucesso! ', bemResponse.data)
        } catch (error) {
          bem.entityStatus = 99
          bem.error = this.errorsToString(error)
          this.cache.actionStatus += ' Falha!'
          // this.alertApiError(error)
        }

        if (bem.entityStatus === 100) {
          // Envia anexos do bem criado
          const uploader = async (files, tipo) => {
            for (let i = 0; i < files.length; i++) {
              // obtém o arquivo
              let file = files.item(i)
              this.cache.copying = true
              this.cache.copyingFile = file
              console.log('Enviando arquivo ', file)
              try {
                let fileContent = await this.fileToBase64(file)
                let uploadResponse = await uploadArquivoBemSync(bemResponse.data.id, {
                  data: fileContent,
                  nome: file.name,
                  filename: file.name,
                  permissao: 0,
                  site: tipo === 'foto-site',
                  tipoCodigo: tipo
                }, this.onUploadProgress)
              } catch (error) {
                alert('Bem ID ' + bemResponse.data.id + '  criado com sucesso, porém houve falha no envio de um ou mais arquivos. Envie manuamente na tela de cadastro do bem. ' + this.errorsToString(error))
              }
              this.cache.fileProgress = 0
              this.cache.copying = false
              this.cache.copyingFile = null
              this.cache.actionStatus = null
            }
          }
          bem.fotos && bem.fotos.length && await uploader(bem.fotos, 'foto-site')
          bem.arquivos && bem.arquivos.length && await uploader(bem.arquivos, 'arquivo')
          bem.matricula && bem.matricula.length && await uploader(bem.matricula, 'matricula')
        }
        /*
            .then((r) => {
              this.cache.actionStatus += ' OK!'
            })
            .catch((e) => {
              this.alertApiError(e)
            })*/
      })
      this.$uloc.window.emit(this.$root.wid, 'updateLotes')
    }
  }
}
</script>

<template>
  <div class="wrapper-md">
    <div v-if="!lote">Criação rápida de Bens/Lotes</div>
    <div v-else>
      <div class="font-14 font-bold">Clonagem de Lotes / Criação de Sublote</div>
      <div class="font-16 m-t-xs">{{lote.numeroString || lote.numero}} - {{lote.bem.siteTitulo}}</div>
    </div>

    <div v-if="!reset" class="m-t e-input-modern size1">
      <e-row>
        <e-col><textarea v-model="model.descricao" style="height: 100px" placeholder="Descrição completa" class="sl-textarea"></textarea></e-col>
      </e-row>
      <e-row mr>
        <e-col><erp-input placeholder="Número do Lote" v-model="model.numero" /></e-col>
        <e-col><erp-input placeholder="Matrícula / Placa" v-model="model.identificador" /></e-col>
      </e-row>
      <e-row mr>
        <e-col style="max-width: 300px">
          <erp-s-field view="tl" label="Descrição Abreviada">
            <erp-input v-model="model.titulo" />
          </erp-s-field>
        </e-col>
        <e-col style="max-width: 80px">
          <erp-s-field view="tl" label="Cód.Tipo">
            <erp-input v-model="cache.tipoBemId" />
          </erp-s-field>
        </e-col>
        <e-col style="max-width: 180px">
          <tipo-bem-select ref="tipoBem" list-all tree label="Tipo de Bem" v-model="model.tipoBem" />
        </e-col>
        <e-col style="max-width: 120px">
          <erp-s-field view="tl" label="Avaliação">
            <erp-input v-model="model.valorAvaliacao" v-money="money" />
          </erp-s-field>
        </e-col>
        <e-col v-if="leilao" style="max-width: 120px">
          <erp-s-field view="tl" :label="leilao.instancia === 1 ? 'Lance Inicial' : '1ª Praça'">
            <erp-input v-model="model.valorInicial" v-money="money" />
          </erp-s-field>
        </e-col>
        <e-col v-if="leilao && leilao.instancia == 2" style="max-width: 120px">
          <erp-s-field view="tl" :label="'% Desconto'">
            <erp-input v-model="model.descontoSegundaPraca" />
          </erp-s-field>
        </e-col>
        <e-col v-if="leilao && leilao.instancia === 2" style="max-width: 120px">
          <erp-s-field view="tl" :label="'2ª Praça'">
            <erp-input v-if="!cache.bindDesconto" ref="valorInicial2" v-model.lazy="model.valorInicial2" v-money="money" />
          </erp-s-field>
        </e-col>
        <e-col v-if="leilao" style="max-width: 120px">
          <erp-s-field view="tl" label="Venda Mínima">
            <erp-input v-model.lazy="model.valorMinimo" v-money="money" />
          </erp-s-field>
        </e-col>
      </e-row>
      <e-row mr>
        <e-col style="max-width: 200px">
          <erp-s-field view="tl" label="Latitude">
            <erp-input v-model="model.lat" />
          </erp-s-field>
        </e-col>
        <e-col style="max-width: 200px">
          <erp-s-field view="tl" label="Longitude">
            <erp-input v-model="model.lng" />
          </erp-s-field>
        </e-col>
      </e-row>
      <e-row mr>
        <e-col>
          <erp-s-field view="tl" label="Fotos (* JPG Máx. 10mb)">
            <input ref="fotos" type="file" accept="image/jpeg" multiple />
          </erp-s-field>
        </e-col>
        <e-col>
          <erp-s-field view="tl" label="Arquivos (PDF)">
            <input ref="arquivos" type="file" accept="image/pdf" multiple />
          </erp-s-field>
        </e-col>
        <e-col>
          <erp-s-field view="tl" label="Matrícula (PDF)">
            <input ref="matricula" type="file" accept="image/pdf" multiple />
          </erp-s-field>
        </e-col>
      </e-row>
      <e-row>
        <e-col class="text-right">
          <u-btn @click="adicionar" label="Adicionar" color="primary" no-caps class="b-radius-3px" />
        </e-col>
      </e-row>
    </div>

    <table class="full-width table-bens-criacao-rapida m-t-lg">
      <thead>
      <tr>
        <th>Descrição dos bens/lotes</th>
        <th style="width: 0px">Editar</th>
        <th style="width: 0px">Remover</th>
      </tr>
      </thead>
      <tbody>
      <tr v-for="bem in bens">
        <td>
          {{bem.titulo}} - Avaliação: {{bem.valorAvaliacao|moeda}} - <span v-if="bem.valorInicial2">1ª Praça: R$ {{bem.valorInicial|moeda}} - Desconto: {{bem.descontoSegundaPraca}}% - 2ª Praça: R$ {{bem.valorInicial2|moeda}}</span><span v-else>Lance Inicial: R$ {{bem.valorInicial|moeda}}</span>
          <div v-if="bem.entityStatus === 99" class="m-t text-negative">{{bem.error}}</div>
        </td>
        <td style="width: 0px" v-if="bem.entityStatus !== 100"><e-btn @click="editar(bem)" label="Editar" /></td>
        <td style="width: 0px" v-if="bem.entityStatus !== 100"><e-btn @click="remover(bem)" label="Remover" /></td>
        <td style="width: 0px" v-if="bem.entityStatus === 100" colspan="2" class="text-center"><i class="fa fa-check text-positive font-16"></i></td>
      </tr>
      </tbody>
      <tfoot v-if="!bens || !bens.length">
      <td colspan="100%">Nenhum bem adicionado para criação</td>
      </tfoot>
    </table>
    <e-row class="m-t-lg">
      <e-col>
        <div v-if="cache.action === 1" :class="cache.actionClass">
          <div>{{cache.actionStatus}}</div>
          <div style="max-width: 300px">
            <u-progress v-if="cache.copying" class="m-t" :percentage="cache.fileProgress" :animate="true"
                        :color="cache.fileProgress < 85 ? 'positive' : 'info'" height="20px" />
          </div>
        </div>
      </e-col>
      <e-col style="max-width: 200px" class="text-right">
        <u-btn @click="salvar" label="Criar bens / lotes" color="positive" no-caps class="b-radius-3px" />
      </e-col>
    </e-row>
  </div>
</template>

<style lang="stylus" src="../assets/criadorBens.styl"></style>
